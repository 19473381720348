// DONE: wakeup form listener on page load
// TODO: redirect users to video if the event is over
import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../../components/CleanLayout';

// import SubtleLogo from '../components/registration/SubtleInsightsSeries/SubtleLogo';
import RegistrationWrapper from '../../components/registration/RegistrationWrapper';
import RegistrationTitle from '../../components/registration/RegistrationTitle';
import RegistrationBody from '../../components/registration/RegistrationBody';
import Schedule from '../../components/registration/Webinar/Schedule';

import Webinar8 from '../../components/registration/webinar8/Webinar8';
import WebinarFormWithCal from '../../components/registration/webinar8/WebinarFormWithCal';
import Speakers from '../../components/registration/webinar9/Speakers';

import styles from '../../components/registration/webinar9/styles.module.scss';

const MODE = 'prod';
const SEND_EMAIL = true;
const FORM_ID = 'rsna-webinar';

// starting v3, set owner from the DB, only pass actual form fields to api endpoint so
// that non-js users have the same experience

const METADATA = [
  {
    name: 'description',
    content: `In this free webinar, hear about the clinical and operational impact of SubtlePET and SubtleMR from several of Subtle Medical's customers, including RadNet, Affidea, DASA, Centre Cardiologique du Nord, and Hoag Hospital. This webinar will be followed by a live Q&A session with Subtle Medical's Head of Clinical, Dr. Praveen Gulaka.`,
  },
  {
    name: 'keywords',
    content:
      'PET, MRI, pilot study, SubtlePET, SubtleMR, RadNet, DASA, Centre Cardiologique du Nord, Affidea, Hoag Hospital, webinar, webinars, Subtle Insights, imaging, scans, dosage, radiology, AI, artificial intelligence, deep learning, Subtle Medical, clinical setting, deployment, medical imaging, scanners',
  },
];

const speakers = [
  {
    key: 'tanenbaum',
    name: 'Dr. Lawrence Tanenbaum',
    title: 'CTO',
    institution: 'RadNet',
  },
  {
    key: 'bash',
    name: 'Dr. Suzie Bash',
    title: 'Director of Neuroradiology',
    institution: 'San Fernando Valley (RadNet)',
  },
  {
    key: 'brant',
    name: 'Dr. Brant-Zawadzki',
    title: 'Senior Physician Executive',
    institution: 'Hoag Hospital',
  },
  {
    key: 'dasa',
    name: 'Dr. Felipe Kitamura',
    title: 'Head of Artifical Intelligence',
    institution: 'DASA (Brazil)',
  },
  {
    key: 'affidea',
    name: 'Dr. Alessandro Roncacci',
    title: 'Chief Medical Officer',
    institution: 'Affidea (Italy)',
  },
  {
    key: 'cnn',
    name: 'Dr. Gerald Bonardel',
    title: 'Nuclear Medicine Physician',
    institution: 'CCN (France)',
  },
  {
    key: 'zaharchuk',
    name: 'Dr. Greg Zaharchuk',
    title: 'Neuroradiologist',
    institution: 'Stanford',
  },
  {
    key: 'gong',
    name: 'Enhao Gong, PhD',
    title: 'Founder',
    institution: 'Subtle Medical',
  },
  {
    key: 'gulaka',
    name: 'Praveen Gulaka, PhD',
    title: 'Head of Clinical',
    institution: 'Subtle Medical',
  },
];

const IndexPage = ({ data }) => {
  // console.log(data);
  return (
    <Layout
      pageTitle="SubtlePET & SubtleMR Webinar | Image Review, Clinical Validation, and Customer Spotlights"
      metadata={METADATA}
    >
      <Img
        fluid={data.xray.childImageSharp.fluid}
        style={{
          opacity: 0.8,
          position: 'fixed',
          top: 0,
          left: 0,
          height: '100vh',
          width: '100%',
          zIndex: -3,
          pointerEvents: 'none',
        }}
      />
      <RegistrationWrapper
        className={styles.subtleinsightsRegistrationWrapper}
        backgroundImageSource={data.doodle.childImageSharp.fluid}
      >
        <RegistrationTitle className={styles.webinarTitleWrapper}>
          <p className={`leading-tight ${styles.webinarTitle}`}>
            <div className="text-subtle-green mb-2">SubtlePET & SubtleMR:</div>
            {/* <div className="mb-2 text-subtle-green text-4xl">Improve Workflow Efficiency & Patient Experience</div> */}
            <div className="text-subtle-blue text-4xl">
              Image Review, Clinical Validation, and Customer Spotlight
            </div>
          </p>
        </RegistrationTitle>

        <div className={styles.webinarDivider} />

        <Schedule className={styles.webinarScheduleContainer}>
          <span className="">Join us for a webinar and Q&A</span>
          <br />
          Wednesday, Dec 16th
          <br />
          10:00am PST / 1:00pm EST
        </Schedule>

        {/* <div className={styles.webinarDivider} /> */}

        <p
          className="text-2xl text-gray-800 mb-6"
          style={{ padding: '0 2rem' }}
        >
          Several of our customers, including RadNet, DASA
          (Brazil), Affidea (Italy), CCN (France), and Hoag Hospital will share
          their experiences with SubtlePET and SubtleMR. You'll see firsthand how Subtle
          Medical has improved the workflow efficiency and patient
          experience of imaging centers around the globe.
        </p>

        <Speakers speakers={speakers} data={data} />

        <div
          style={{ paddingTop: '1rem' }}
          className={`${styles.subtleinsightsFlexWrapper} items-top`}
        >
          <div className={styles.subtleinsightsFlexRight}>
            <WebinarFormWithCal
              eventId="Nu5705921"
              minHeight="670" // px so we can easily offset from top; needed to keep from jangling on state change
              MODE={MODE}
              SEND_EMAIL={SEND_EMAIL}
              FORM_ID={FORM_ID}
            />
          </div>

          <div className={styles.subtleinsightsFlexLeft}>
            <RegistrationBody>
              <div className={styles.webinarBody}>
                <p className={styles.webinarSubheading}>
                  Webinar Overview:
                  <br />
                  <span
                    className={styles.underline}
                    style={{ width: '230px' }}
                  />
                </p>
                <ul className="list-disc list-inside leading-snug">
                  <li className="mb-1 mt-2 leading-1">Image reviews</li>
                  <li className="mb-1 leading-1">
                    Real-world clinical validation
                  </li>
                  <li className="mb-1 leading-1">Clinical pilots</li>
                  <li className="mb-1 leading-1">Customer spotlights</li>
                </ul>
              </div>
            </RegistrationBody>
          </div>
        </div>

        <p className={styles.webinarBody}>
          Register now to reserve your seat or to receive a recording after the
          event. If you are unable to attend, but are interested in learning
          more, please email sales@subtlemedical.com.
        </p>
        <a href="https://subtlemedical.com/">
          <Img
            style={{ maxWidth: '600px', margin: 'auto', marginTop: '1rem' }}
            fluid={data.subtlelogo.childImageSharp.fluid}
          />
        </a>
        <h2 style={{ textAlign: 'center', paddingTop: '2rem' }}>
          Hosted by the Subtle Insights Webinar Series
        </h2>
      </RegistrationWrapper>
    </Layout>
  );
};

export const query = graphql`
  {
    xray: file(relativePath: { eq: "resources/mast-insights-bg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600, grayscale: true) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    doodle: file(relativePath: { eq: "resources/subtle-medical-overlay.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, grayscale: false) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    subtlelogo: file(
      relativePath: { eq: "resources/subtle-medical-logo-clean.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    tanenbaum: file(
      relativePath: { eq: "resources/Webinars/9/tanenbaum.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bash: file(relativePath: { eq: "resources/Webinars/9/bash.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    brant: file(relativePath: { eq: "resources/Webinars/9/brant.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    gulaka: file(relativePath: { eq: "resources/Webinars/9/praveen.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    dasa: file(relativePath: { eq: "resources/Webinars/9/kitamura.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    affidea: file(relativePath: { eq: "resources/Webinars/9/affidea.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    cnn: file(relativePath: { eq: "resources/Webinars/9/bonardel.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    gong: file(relativePath: { eq: "resources/Webinars/9/gong.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    zaharchuk: file(
      relativePath: { eq: "resources/Webinars/9/zaharchuk.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default IndexPage;
