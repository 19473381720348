import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import styles from '../Webinar5/webinar.module.scss';

function SpeakerIntro({
  children,
  speakerImage,
  speakerAlt,
  imageHeight,
  imageWidth,
  className = '',
  // styles,
}) {
  return (
    <section>
      {/* // <section className={styles.componentsSpeakerContainer}> */}
      {/* // <div className={styles.componentsSpeakerFlexContainer}> */}
      <div className={styles.speakerImageWrapper}>
      <Img
        className={styles.speakerImageWrapper}
        fixed={speakerImage}
        style={{
          borderRadius: '50%',
          minWidth: '150px',
        }}
        alt={speakerAlt}
        />

        </div>
    </section>
  );
}

SpeakerIntro.propTypes = {
  children: PropTypes.node,
  speakerImage: PropTypes.object.isRequired,
  speakerAlt: PropTypes.string,
  imageHeight: PropTypes.string,
  imageWidth: PropTypes.string,
};

SpeakerIntro.defaultProps = {
  speakerAlt: 'Subtle Insights Webinar Guest Speaker',
  imageHeight: '150px',
  imageWidth: '150px',
  children: null,
};

export default React.memo(SpeakerIntro);
